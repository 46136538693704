import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mobilemenu: boolean;
  $loggedIn: boolean;
  userID: any;
  userinfo: any;
  userRole: any;
  constructor(
    public authService: AuthService,
    public router: Router,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore
  ) { }

  ngOnInit() {
    let tempObj = this;
    if (this.authService.isLogedin) {
      this.$loggedIn = true;
    } else {
      this.$loggedIn = false;
    }
    
    // this.afAuth.authState.subscribe(res => {
    //   if (res && res.uid) {
    //     this.$loggedIn = true;
    //     this.firestore.doc(`Users/${ res.uid }`).ref.get().then(function(doc) {
    //       if (doc.exists) {
    //         tempObj.userRole = doc.data().Role;
    //       } else {
    //         tempObj.userRole = "";
    //       }
    //     }).catch(function(error) {
    //       //console.log("Error getting document:", error);
    //     });
    //   } else {
    //     this.$loggedIn = false;
    //   }
    // });
    this.checkRole();
  }
  checkRole(){
    if(this.$loggedIn == true && this.userRole == "customer"){
      return false;
    }
    else if(this.$loggedIn == true && this.userRole == "admin"){
      return true;
    }
  }
  ToggleMmenu() {
    if (this.authService.isLogedin) {
      this.$loggedIn = true;
    } else {
      this.$loggedIn = false;
    }
    this.mobilemenu = !this.mobilemenu;
  }

  logout() {
    this.authService.setUser = {}
    // this.authService.logout();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 300);
  }
}
