import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// export interface UserDetails {
//   displayName: string;
//   photoURL: string;
//   phoneNumber: string;
// }
export class AuthInfo {
  constructor(
    public $uid: string
  ) { }
  isLoggedIn() {
    return !!this.$uid;
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url = 'https://robot.massagerobotics.com'; // 'http://35.232.91.223:8004';

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private localStorage: LocalStorage,
    private firestore: AngularFirestore,
    private http: HttpClient
  ) {
    // this.user = afAuth.authState;
   
    // this.GetUserData();
  }
  static UNKNOWN_USER = new AuthInfo(null);
  userinformation: any;
  // user: Observable<firebase.User>;
  authState: AngularFireAuth = null;
  currentUserObservable: any;
  authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthService.UNKNOWN_USER);
  @Output() public loginError: EventEmitter<any> = new EventEmitter();
  @Output() public SignupError: EventEmitter<any> = new EventEmitter();
  @Output() public ForgotError: EventEmitter<any> = new EventEmitter();


  httpOptions = {
    headers: new HttpHeaders({
      //'Authorization': 'Token ' + this.getUser.token
      'Authorization': 'Token ' + (this.getUser ? this.getUser.token : "")
    })
  }

  // Sign up with email/password
  signup(data) {
    const userinfo = data;
    return this.afAuth
      .auth
      .createUserWithEmailAndPassword(userinfo.email, userinfo.password)
      .then(() => {
        this.router.navigate(['profile']);
        this.updateCurrentUser(userinfo);
        const userName = userinfo.fname + ' ' + userinfo.lname;
        this.localStorage.setItem('user', userName).subscribe(() => { });
        setTimeout(() => {
          this.localStorage.clear().subscribe(() => { });
        }, 2000);
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
          this.SignupError.emit('The username and password you entered did not match our records. Please double-check and try again.');
        } else if (error.code === 'auth/user-disabled') {
          this.SignupError.emit('Your account has been suspended. Please contact us directly to discuss this.');
        } else {
          this.SignupError.emit(error.message);
        }
      });
  }

  GetCurrentUserData(userid){
    //  this.firestore.doc(`Users/${ userid }`).ref.get().then(function(doc) {
    //   if (doc.exists) {
    //     let role = doc.data().Role;
    //     console.log(role);
    //     return role;
    //   } else {
    //     console.log("No such document!");
    //     let role = "";
    //     console.log(role);
    //     return role;
    //   }
    // }).catch(function(error) {
    //   console.log("Error getting document:", error);
    // });
  }

  
  getUserinfo(userid) {
    return this.firestore.collection(`profile${userid}`).snapshotChanges();
  }
  GetUserData(userid) {
    return this.getUserinfo(userid)
    .pipe(
      first(),
    )
    .subscribe(data => {
      //console.log(data);
      this.userinformation = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        };
      });
      // console.log(userid);
      // console.log(this.userinformation);
      const currentUser = this.userinformation;
      const Logindate = firebase.firestore.FieldValue.serverTimestamp();
      //const Logindate = new Date();
      console.log(Logindate);
      //const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`Users/${userid}/${currentUser.id}`);

      
     const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`Users/${userid}`);
     
      const userinfo = {
        LoginDate: Logindate,
        UserEmail: currentUser[0].UserEmail,
        UserName: currentUser[0].UserName,
        UserPhoneNumber: currentUser[0].UserPhoneNumber,
        createDate: currentUser[0].createDate,
      };
      return userRef.set(userinfo, { merge: true });
    });
  }
  // Sign in with email/password
  login(email: string, password: string): Observable<AuthInfo> {
    return this.fromFirebaseAuthPromise(
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          this.router.navigate(['profile']);
          this.updateLastLogin(this.afAuth.auth.currentUser.uid);
          //return this.GetUserData(this.afAuth.auth.currentUser.uid);

        }).catch(error => {
          if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
            this.loginError.emit('The username and password you entered did not match our records. Please double-check and try again.');
          } else if (error.code === 'auth/user-disabled') {
            this.loginError.emit('Your account has been suspended. Please contact us directly to discuss this.');
          } else {
            this.loginError.emit(error.message);
          }
        })
    );
  }

  pySignup(req) {
    return this.http.post(`${this.url}/user/register/`, req);
  }
  
  pyLogin(req) {
    return this.http.post(`${this.url}/login`, req);
  }

  pyFetchUserProfile() {
    return this.http.get(`${this.url}/user/profile`, this.httpOptions);
  }

  pyUpdateProfile(req) {
    return this.http.patch(`${this.url}/user/profile/`, req, this.httpOptions);
  }
  
  sendContactEmail(req) {
    return this.http.post(`${this.url}/user/contact/`, req);
  }

  pyGetPreferenceQuestions() {
    return this.http.get(`${this.url}/user/preference-questions/`, this.httpOptions);
  }

  pyAnswerPreferenceQuestion(req) {
    return this.http.patch(`${this.url}/user/preference-questions/`, req, this.httpOptions);
  }

  set setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  get getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  get isLogedin() {
    return this.getUser && this.getUser.token ? true : false;
  }
  
  updateLastLogin(userid){
   
    const Lastlogin = new Date();
    this.firestore.collection('Users')
      .doc(userid)
      .update({
        "Lastlogin": Lastlogin
      });
  }
  // Sign out
  logout() {
    this.afAuth
      .auth
      .signOut();
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
        this.router.navigate(['login']);
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
          this.ForgotError.emit('The username and password you entered did not match our records. Please double-check and try again.');
        } else if (error.code === 'auth/user-disabled') {
          this.ForgotError.emit('Your account has been suspended. Please contact us directly to discuss this.');
        } else {
          this.ForgotError.emit(error.message);
        }
      });
  }
  getUsersInfo(): Observable<any> {
    return this.afAuth.authState.pipe(map((user) => {
      return user;
    }));
  }

  updateCurrentUser(userinfo) {

    return new Promise<any>((resolve, reject) => {
      const user = firebase.auth().currentUser;
      user.updateProfile({
        displayName: userinfo.fname + ' ' + userinfo.lname,
        photoURL: userinfo.photoURL,
        // phoneNumber: userinfo.phone,
      }).then(res => {
        resolve(res);
      }, err => reject(err));

      let UserName: string;
      // tslint:disable-next-line: prefer-const
      let UserEmail: string;
      // tslint:disable-next-line: prefer-const
      let UserPhoneNumber: string;

      UserName = userinfo.fname + ' ' + userinfo.lname;
      UserEmail = userinfo.email;
      UserPhoneNumber = userinfo.phone;
      const createDate = firebase.firestore.FieldValue.serverTimestamp();
      const LoginDate = new Date();
      // tslint:disable-next-line: max-line-length
      const role = 'customer';
      // tslint:disable-next-line: max-line-length
      this.firestore.collection('Users').doc(this.afAuth.auth.currentUser.uid).set({
        Name: UserName,
        Email: UserEmail,
        PhoneNumber: UserPhoneNumber,
        Role: role,
        Registered: createDate,
        Lastlogin: LoginDate
    });
    });
  }
  fromFirebaseAuthPromise(promise): Observable<any> {
    const subject = new Subject<any>();
    promise
      .then(res => {
        const authInfo = new AuthInfo(this.afAuth.auth.currentUser.uid);
        this.authInfo$.next(authInfo);
        subject.next(res);
        subject.complete();
      },
        err => {
          this.authInfo$.error(err);
          subject.error(err);
          subject.complete();
        });
    return subject.asObservable();
  }

}
