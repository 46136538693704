import { Component } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  template: `
  <app-header *ngIf="router.url != '/404'"></app-header>
  <router-outlet><div id="preloader" *ngIf="loading"></div></router-outlet>
  <app-footer *ngIf="router.url != '/404'"></app-footer>`
})
export class AppComponent {

  loading = true;
  constructor(public router: Router) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    }
    if (event instanceof NavigationCancel) {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    }
  }
}
