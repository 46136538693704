import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) , pathMatch: 'prefix' },
  { path: 'landing-right', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'landing-left', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'landing-back', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'naked-are-you-safer-with-a-stranger-or-a-robot', loadChildren: () => import('./naked/naked.module').then(m => m.NakedModule) },
  { path: 'article',    redirectTo: '/article/overview', pathMatch: 'full'  },
  { path: 'article/:url', loadChildren: () => import('./article/article.module').then(m => m.ArticleModule) },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
  // tslint:disable-next-line: max-line-length
  { path: 'preference', loadChildren: () => import('./preference/preference.module').then(m => m.PreferenceModule), canActivate: [AuthGuard] },
  { path: 'robot', loadChildren: () => import('./robot/robot.module').then(m => m.RobotModule), canActivate: [AuthGuard]},
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule) },
  { path: 'investor', loadChildren: () => import('./investor/investor.module').then(m => m.InvestorModule), canActivate: [AuthGuard] },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'forgot-password', loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotModule) },
  { path: 'registration', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: 'feedback', loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule) },
  { path: 'multiimages', loadChildren: () => import('./multiimages/multiimages.module').then(m => m.MultiimagesModule) },
  { path: 'business-card', loadChildren: () => import('./business-card/business-card.module').then(m => m.BusinessCardModule) },
  { path: 'terms', loadChildren: () => import('./legal/terms/terms.module').then(m => m.TermsModule) },
  { path: 'privacy', loadChildren: () => import('./legal/privacy/privacy.module').then(m => m.PrivacyModule) },

  { path: 'dashboard', loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule) },
  





  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
